import { useHistory } from 'react-router-dom';
import {
  Menu,
} from 'antd';
import { useStores } from '@/store';
import './index.less';
import menuConfigs from './config';

/**
 * 后台-左侧菜单
 * @param defaultOpenKeys 初始展开的项的key数组
 * @param defaultSelectedKeys 初始选中的项的key数组
 */
export default function LeftMenu({
  defaultSelectedKeys = [],
  defaultOpenKeys = [],
} : {
  defaultSelectedKeys?: string[],
  defaultOpenKeys?: string[],
}) {
  const stores = useStores();
  const { UserInfoStore } = stores;
  const { userPermission, isSpecial } = UserInfoStore;
  const { SubMenu } = Menu;
  const history: any = useHistory();

  function handleClick(path: string) {
    history.push(path);
  }

  const renderMenu = (list: any[]) => (
    list.map((item) => {
      if (item.special && !isSpecial) {
        return '';
      }
      if (userPermission.includes(item.perm) || !item.perm) {
        if (item.children && Array.isArray(item.children)) {
          return (
            <SubMenu
              key={item.path}
              title={item.key}
              icon={item.icon ? item.icon : false}
            >
              {renderMenu(item.children)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item
            key={item.path}
            icon={item.icon ? item.icon : false}
            onClick={() => handleClick(item.path)}
          >
            {item.key}
          </Menu.Item>
        );
      }
      return '';
    })
  );

  return (
    <div className="admin-left-menu">
      <Menu
        key={defaultSelectedKeys[0]}
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        style={{ height: '100%', borderRight: 0 }}
      >
        {renderMenu(menuConfigs)}
      </Menu>
    </div>
  );
}
